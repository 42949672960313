import { Charge, ERROR_CODE, ERROR_TYPE, PaymentSource } from '@stronghold/pay-dropin';

export interface Response<T> {
    result: T;
}

export interface ErrorResponse {
    error: {
        type: ERROR_TYPE;
        code: ERROR_CODE;
        message: string;
        property?: string;
    };
}

export interface Merchant {
    display_name: string;
    logo_url: string;
    has_credit_card_payments: boolean;
}

export interface SentryConfiguration {
    environment: string;
    dsn: string;
}

export interface DataDogConfiguration {
    environment: string;
    client_token: string;
    site: string;
}

export interface TsepConfiguration {
    script_path: string;
    credit_card_api_url: string;
    manifest: string;
}

export interface Configuration {
    merchant: Merchant;
    support_email: string;
    api_version: string;
    customer_id: string;
    sentry: SentryConfiguration;
    tsep: TsepConfiguration;
    data_dog: DataDogConfiguration;
}

export enum AGGREGATOR_VENDOR {
    Plaid = 'plaid',
    Yodlee = 'yodlee',
    Finicity = 'finicity',
}

export enum TsepEnvironment {
    None = 'none',
    Sandbox = 'sandbox',
    Live = 'live',
}

export interface AddPaymentSourceConfig {
    aggregator_vendor: AGGREGATOR_VENDOR;
    plaid?: {
        link_token: string;
        cache_key: string;
    };
    yodlee?: {
        url: string;
        access_token: string;
    };
    finicity?: {
        connect_url: string;
    };
}

export interface UpdatePaymentSourceConfig extends AddPaymentSourceConfig {
    is_re_authentication_needed: boolean;
    aggregator_vendor: AGGREGATOR_VENDOR;
    payment_source: PaymentSource;
    plaid?: {
        link_token: string;
        cache_key: string;
        account_id?: string;
    };
    yodlee?: {
        url: string;
        access_token: string;
        provider_account_id: number;
    };
    finicity?: {
        connect_url: string;
    };
}

export interface RetryAddPaymentSourceModel {
    public_token?: string;
    plaid_account_id?: string;
    provider_account_id?: number;
}

export interface AddChargeConfig {
    add_convenience_fee: boolean;
    convenience_fee: number;
    payment_source: PaymentSource;
}

export interface AddChargeModel {
    amount: number;
    payment_source_id: string;
    external_id?: string;
}

export interface TipChargeDataModel {
    amount: number;
    beneficiary_name: string;
    details: {
        display_message: string | null;
        drawer_id: string | null;
    };
}

export interface AddChargeRequestModel {
    pay_link_id?: string | null;
    authorize_only: boolean;
    charge: AddChargeModel;
    tip?: TipChargeDataModel | null;
}

export interface AddTipConfig {
    payment_source: PaymentSource;
    charge: Charge;
}

export interface TipDataModel extends TipChargeDataModel {
    payment_source_id: string;
    charge_id: string;
}

export interface AddTipRequestModel {
    pay_link_id?: string | null;
    authorize_only: boolean;
    tip: TipDataModel;
}

export interface MaxPromotionValue {
    value: number;
}

export interface ProcessingFeeAmountModel {
    payment_processing_fee: number;
}
