import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ERROR_CODE, StrongholdPayError } from '@stronghold/pay-dropin';
import { Button } from 'reactstrap';
import DropinContent from './DropinContent';
import DropinBody from './DropinBody';
import DropinFooter from './DropinFooter';
import CoBrand from './CoBrand';
import notifier from '../dropin/notifier';
import { MESSAGE } from '../message';
import { ApplicationState } from '../store';
import { AGGREGATOR_ERROR_CODES } from '../errorCodes';
import { getErrorMessage } from '../dropin/utils';

interface StateProps {
    error: StrongholdPayError | null;
    message: string | null;
    supportEmail: string | null;
}

interface OwnProps {
    onClick?: () => void;
}

type Props = StateProps & OwnProps;

class DropinError extends React.PureComponent<Props> {
    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        } else {
            notifier.exit();
        }
    };

    renderContact = () => {
        return (
            <div>
                Please contact <a href={`mailto: ${this.props.supportEmail}`}>{this.props.supportEmail}</a> if you have
                any questions.
            </div>
        );
    };

    render() {
        let text = MESSAGE.SOMETHING_WENT_WRONG;
        let closeButtonText = 'Close';
        let reference = this.props.error?.reference;
        let showContactSupport = true;

        if (this.props.message) {
            text = this.props.message;
            if (this.props.error) {
                switch (this.props.error.code.toString()) {
                    case AGGREGATOR_ERROR_CODES.AGGREGATOR_SUPPORT_ERROR:
                        // Prevent redundant contact support message from rendering since consumers should reach out to the
                        // payment aggregator vendor for assistance
                        showContactSupport = false;
                        break;
                    case AGGREGATOR_ERROR_CODES.INCOMPATIBLE_YODLEE_ACCOUNT:
                    case AGGREGATOR_ERROR_CODES.AGGREGATOR_INTEGRATION_ERROR:
                        showContactSupport = true;
                        break;
                }
            }
        } else if (this.props.error) {
            text = getErrorMessage(this.props.error, this.props.message);
            switch (this.props.error.code) {
                case ERROR_CODE.INSUFFICIENT_BALANCE:
                    closeButtonText = `Continue`;
                    break;
                case ERROR_CODE.PAYMENT_SOURCE_UNAVAILABLE:
                    closeButtonText = `Continue`;
                    break;
                case ERROR_CODE.PAYMENT_SOURCE_LOGIN_UNAVAILABLE:
                    closeButtonText = `Continue`;
                    break;
                case ERROR_CODE.PAYMENT_SOURCE_INACTIVE:
                    closeButtonText = `Continue`;
                    break;
                case ERROR_CODE.PAYMENT_SOURCE_ACTION_REQUIRED:
                    closeButtonText = `Continue`;
                    break;
                case ERROR_CODE.CUSTOMER_BLOCKED:
                    showContactSupport = true;
                    break;
                case ERROR_CODE.CHARGE_BLOCKED_OUTSTANDING_PAYMENT:
                    showContactSupport = true;
                    break;
                case ERROR_CODE.CHARGE_TIP_ALREADY_CREATED:
                    closeButtonText = `Continue`;
                    break;
                case ERROR_CODE.PAYMENT_SOURCE_LOGIN_REQUIRED:
                    closeButtonText = `Continue`;
                    break;
                case ERROR_CODE.PAY_LINK_CANCELED:
                    showContactSupport = false;
                    break;
                case ERROR_CODE.INVALID_CHARGE_AMOUNT:
                case ERROR_CODE.INVALID_TIP_AMOUNT:
                    showContactSupport = false;
                    break;
            }
        }

        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div id="error-text">{text}</div>
                    {showContactSupport && this.renderContact()}
                    <small id="error-reference" className="font-weight-bold">
                        {reference}
                    </small>
                </DropinBody>
                <DropinFooter>
                    <Button color="primary" block onClick={this.onClick}>
                        {closeButtonText}
                    </Button>
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => ({
    error: state.error.error,
    message: state.error.message,
    supportEmail: state.dropin.supportEmail || "support@stronghold.co",
});

export default connect(mapStateToProps)(DropinError);
