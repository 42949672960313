import {Reducer} from 'redux';
import {DropinAction} from '../actions';
import {DropinState, QueryDataProcessed} from '../types';
import * as Sentry from '../../sentry';
import * as DataDog from '../../datadog';
import * as selectors from '../selectors';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedQuery = {
    authorizeOnly: false,
    customerToken: '',
    payLinkId: null,
    paymentSourceId: null,
    publishableKey: '',
    integrationId: '',
    skipPayScreen: false,
    skipSuccessScreen: false,
    charge: null,
    tip: null,
    isLogin: false,
};

const unloadedState: DropinState = {
    tsep: null,
    customerToken: null,
    publishableKey: null,
    integrationId: null,
    merchant: null,
    supportEmail: null,
    sentry: null,
    customerId: null,
    query: unloadedQuery,
    ready: false,
    api_version: null,
};

export const dropin: Reducer<DropinState, DropinAction> = (state: DropinState = unloadedState, action) => {
    switch (action.type) {
        case 'SET_CUSTOMER_TOKEN':
            return {
                ...state,
                customerToken: action.payload,
            };
        case 'SET_PUBLISHABLE_KEY':
            return {
                ...state,
                publishableKey: action.payload,
            };
        case 'SET_INTEGRATION_ID':
            return {
                ...state,
                integrationId: action.payload,
            }
        case 'SET_CONFIGURATION':
            DataDog.initializeDataDog({
                environment: action.payload.data_dog.environment,
                 clientToken: action.payload.data_dog.client_token,
                 site: action.payload.data_dog.site,
            });
            
            Sentry.initializeSentry({
                customerId: action.payload.customer_id,
                dsn: action.payload.sentry.dsn,
                environment: action.payload.sentry.environment,
            });
            return {
                ...state,
                merchant: action.payload.merchant,
                supportEmail: action.payload.support_email,
                api_version: action.payload.api_version,
                customerId: action.payload.customer_id,
                sentry: action.payload.sentry,
                tsep: action.payload.tsep,
            };
        case 'SET_QUERY_DATA':
            return {
                ...state,
                query: {
                    customerToken: action.payload.customerToken,
                    publishableKey: action.payload.publishableKey,
                    integrationId: action.payload.integrationId,
                    authorizeOnly: action.payload.authorizeOnly?.toLowerCase() === 'true',
                    skipPayScreen: action.payload.skipPayScreen?.toLowerCase() === 'true',
                    skipSuccessScreen: action.payload.skipSuccessScreen?.toLowerCase() === 'true',
                    payLinkId: action.payload.payLinkCode || null,
                    paymentSourceId: action.payload.paymentSourceId || null,
                    charge: action.payload.chargeAmount
                        ? {
                              amount: selectors.toNumber(action.payload.chargeAmount) || 0,
                              paymentSourceId: action.payload.chargePaymentSourceId || null,
                              externalId: action.payload.chargeExternalId || null,
                          }
                        : null,
                    tip: action.payload.tipAmount
                        ? {
                              amount: selectors.toNumber(action.payload.tipAmount) || 0,
                              paymentSourceId: action.payload.tipPaymentSourceId || null,
                              beneficiaryName: action.payload.tipBeneficiaryName || null,
                              chargeId: action.payload.tipChargeId || null,
                              details: {
                                  displayMessage: action.payload.tipDetailsDisplayMessage || null,
                                  drawerId: action.payload.tipDetailsDrawerId || null,
                              },
                          }
                        : null,
                    isLogin: action.payload.isLogin?.toLowerCase() === 'true',
                } as QueryDataProcessed,
            };
        case 'SET_AS_READY':
            return {
                ...state,
                ready: true,
            };
        case 'SET_TSEP_MANIFEST':
            return {
                ...state,
                tsep: {
                    script_path: state.tsep!.script_path,
                    credit_card_api_url: state.tsep!.credit_card_api_url,
                    manifest: action.payload.manifest,
                },
            };
        default:
            return state;
    }
};
